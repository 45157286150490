import * as React from 'react';
import * as _ from 'lodash';
import './groupMembersGrid.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as StM from '../../../../models/store';
import { AgGridReact } from 'ag-grid-react';
import * as SrvM from '../../../../services';

interface IGroupMembersGridProps {
    adminUserId?: string;
    data?: StM.IGroupMemberStoreState[];

    onRemove?: (item: IMemberItem) => void;
    onResendInvite?: (item: IMemberItem) => void;
    onSwitchAdminAccess?: (item: IMemberItem) => void;
}

export interface IMemberItem {
    id: number;
    userId: string;
    isAdmin: boolean;
    status: string;
    type: StM.GroupMemberType;
    email: string;
    name: string;

    age?: number;
    gender?: string;
    skill?: string;
}

export class GroupMembersGrid extends React.Component<IGroupMembersGridProps> {
    constructor(props: IGroupMembersGridProps){
        super(props);
    }

    private utils = new SrvM.Utils();

    public render() {
        return (
            <div className='group-members-grid ag-theme-alpine'>
                <AgGridReact
                    domLayout='autoHeight'
                    rowData={this.getMembersListData()}
                    columnDefs={this.getGridColumns()}
                    tooltipShowDelay={10}
                />
            </div>
        );
    }

    private getGridColumns(): Array<any> {
        return [
            {
                field: 'name',
                headerName: 'Name',
                tooltipField: "name",
                resizable: false,
                flex: 1.5,
                cellClass: params => this.utils.getCellClassNames(params.data.type)
            },
            { 
                field: 'email',
                headerName: 'Email',
                tooltipField: "email",
                resizable: false,
                flex: 2,
                cellClass: params => this.utils.getCellClassNames(params.data.type),
            },
            { 
                field: 'age',
                headerName: 'Age',
                resizable: false,
                flex: 1,
                cellClass: params => this.utils.getCellClassNames(params.data.type)
            },
            { 
                field: 'gender',
                headerName: 'Gender',
                resizable: false,
                flex: 1,
                cellClass: params => this.utils.getCellClassNames(params.data.type)
            },
            {
                field: 'skill',
                headerName: 'Skill',
                tooltipField: "skill",
                resizable: false,
                flex: 1,
                cellClass: params => this.utils.getCellClassNames(params.data.type)
            },
            {
                field: 'status',
                headerName: 'Status',
                resizable: false,
                flex: 1,
                cellClass: params => this.utils.getCellClassNames(params.data.type)
            },
            {
                field: 'resendInvite',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 1.75,
                cellRenderer: (params) => {
                    return (
                        params.data.status ? (
                            params.data.role !== 'Member' || this.props.adminUserId === params.data.userId ? (
                                <a
                                className='k-button'
                                onClick={() => this.handleResendInviteClick(params.data)}
                                >
                                Resend Invite
                                </a>
                            ) : null
                        ) : (
                            params.data.type === StM.GroupMemberType.Head || this.props.adminUserId === params.data.userId ? (
                                null
                            ) : (                             
                                <a
                                className='k-button'
                                onClick={() => this.handleSwitchAdminAccessClick(params.data)}
                                >
                                { params.data.isAdmin ? StM.Strings.RemoveAdminStatus : StM.Strings.MakeGroupAdmin }
                                </a>
                            )
                        )      
                    )
                }
            },
            {
                field: 'remove',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 0.6,
                cellRenderer: (params) => (
                    params.data.type === StM.GroupMemberType.Head || params.data.userId === this.props.adminUserId ? (
                        null
                    ) : (
                        <a
                            href='#'
                            role='button'
                            className='remove-btn'
                            onClick={(e) => this.handleRemoveMemberClick(params.data)}
                        />
                    )
                ),
            },
        ];
    }

    private getMembersListData():IMemberItem[] {
        return _.sortBy(this.props.data.map((item) => {
            const isInvitationAccepted = item.invitationStatus === StM.InvitationStatus.Accepted;
            let result: IMemberItem = {
                id: item.id,
                userId: item.user.id,
                isAdmin: item.canLoginAsGroupAccount,
                status: !isInvitationAccepted ? item.invitationStatus : '',
                type: item.type,
                email: item.email,
                name: item.user ? item.user.displayName : '',
            };
            if(isInvitationAccepted) {
                result.age = item.age;
                result.gender = item.gender;
                result.skill = item.user ? item.user.skill : '';
            }

            return result;
        }),[(a) => a.type !== StM.GroupMemberType.Head, (a) => _.startCase(_.toLower(a.name))]);
    }

    private handleResendInviteClick(dataItem: IMemberItem) {
        if(this.props.onResendInvite){
            this.props.onResendInvite(dataItem);
        }
    }

    private handleSwitchAdminAccessClick(dataItem: IMemberItem) {
        if(this.props.onSwitchAdminAccess){
            this.props.onSwitchAdminAccess(dataItem);
        }
    }

    private handleRemoveMemberClick(dataItem: IMemberItem) {
        if(this.props.onRemove){
            this.props.onRemove(dataItem);
        }
    }
}